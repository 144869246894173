import { Inject, Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth-service/auth.service';
import { JwtService } from './jwt.service';
import { environment } from '@mkx/environments/environment';
import { APP_ENDPOINTS } from '@mkx/configs/app-endpoints';
import { APP_ROUTES } from '@mkx/configs/app-routes';
import { ApiErrorResponseModel } from '@mkx/models/api-error-response.model';
import { APIErrorModel } from '@mkx/models/api-error.model';
import { TOAST_TYPES } from '@mkx/services/toaster/utils/toast-config';
import { ToastService } from '@mkx/services/toaster/toast-service/toast.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  private s3Url = 'https://s3.amazonaws.com/babuin.files';

  constructor(
    private injector: Injector,
    private router: Router,
    private jwtService: JwtService,
    @Inject(APP_ENDPOINTS)
    private appEndpoints,
    @Inject(APP_ROUTES)
    private appRoutes,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = request.clone({
      url: this.updateUrl(request.url),
      headers: this.setHeaders(request.url),
    });

    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  private refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.authService.refreshToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError((error: any) => {
          this.refreshTokenInProgress = false;
          if (error.status === 401) {
            this.authService.logout();
          }
          return throwError(error);
        })
      );
    }
  }

  private handleResponseError(error, request?, next?) {
    if (error.status === 400) {
      const responseBody = error.error;

      if ('errors' in responseBody) {
        const apiErrorResponse = new ApiErrorResponseModel(responseBody);
        let message = '';
        apiErrorResponse.getErrors().forEach((element: APIErrorModel) => {
          if (element.getProperty() === null) {
            message = message + '- ' + element.getMessage() + '\n';
          }
        });

        if (message !== '') {
          this.toastService.show({
            type: TOAST_TYPES.WARNING,
            text: message,
            translate: false,
          });
        }

        return throwError(apiErrorResponse);
      }
    } else if (error.status === 500) {
      this.toastService.show({ type: TOAST_TYPES.ERROR });
      return [];
    } else if (error.status === 403) {
      this.toastService.show({
        type: TOAST_TYPES.WARNING,
        translate: true,
        text: 'forbidden_message',
      });
      return [];
    } else if (error.status === 401) {
      if (
        request.url.includes('login') ||
        request.url.includes('token/refresh')
      ) {
        return throwError(error);
      }

      return this.refreshToken().pipe(
        switchMap(() => {
          request = request.clone({
            url: this.updateUrl(request.url),
            headers: this.setHeaders(request.url),
          });
          return next.handle(request);
        })
      );
    } else if (error.status === 404) {
      if (
        !request.url.includes('videos') &&
        !request.url.includes('appearance')
      ) {
        this.router.navigate(['/' + this.appRoutes.notFound]);
        return [];
      }
    } else if (error.status === 503) {
      this.router.navigate(['/' + this.appRoutes.maintenance]);
      return [];
    }

    return throwError(error);
  }

  private updateUrl(url: string) {
    return url.startsWith('http://') ||
      url.startsWith('https://') ||
      url.includes('/assets/')
      ? url
      : environment.apiUrl + url;
  }

  private setHeaders(url: string): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (this.jwtService.getToken() && !url.includes(this.s3Url)) {
      headersConfig['Authorization'] = `Bearer ${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }
}
